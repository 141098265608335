import { useFetcher } from '@remix-run/react';
import * as React from 'react';
import { Button } from '~/components/button.tsx';
import { Field } from '~/components/form-elements.tsx';
import { CheckIcon } from '~/components/icons.tsx';
import { useRootData } from '~/utils/use-root-data.ts';
import { type ActionData } from './types.ts';

function ConvertKitForm({
  formId,
  convertKitTagId,
  convertKitFormId
}: { formId: string } & (
  | { convertKitTagId?: never; convertKitFormId: string }
  | { convertKitTagId: string; convertKitFormId?: never }
  | { convertKitTagId: string; convertKitFormId: string }
)) {
  const websiteId = React.useId();
  const convertKit = useFetcher<ActionData>();
  const formRef = React.useRef<HTMLFormElement>(null);
  const isDone = convertKit.state === 'idle' && convertKit.data != null;
  const convertKitData = isDone ? convertKit.data : null;
  React.useEffect(() => {
    if (formRef.current && convertKitData?.status === 'success') {
      formRef.current.reset();
    }
  }, [convertKitData]);

  const { userInfo } = useRootData();

  const alreadySubscribed = userInfo?.convertKit?.tags.some(({ id }) => id === convertKitTagId);

  if (alreadySubscribed) {
    return <div>{`Al parecer ya estábas registrado.`}</div>;
  }

  const success = isDone && convertKitData?.status === 'success';

  return (
    <convertKit.Form ref={formRef} action="/action/convert-kit" method="POST" noValidate autoComplete="off">
      <div style={{ position: 'absolute', left: '-9999px' }}>
        <label htmlFor={`website-url-${websiteId}`}>Your website</label>
        {/* eslint-disable-next-line jsx-a11y/autocomplete-valid */}
        <input type="text" id={`website-url-${websiteId}`} name="url" tabIndex={-1} autoComplete="nope" />
      </div>
      <input type="hidden" name="formId" value={formId} />
      <input type="hidden" name="convertKitTagId" value={convertKitTagId} />
      <input type="hidden" name="convertKitFormId" value={convertKitFormId} />
      <Field
        name="firstName"
        label="Nombre"
        error={convertKitData?.status === 'error' ? convertKitData.errors.firstName : null}
        autoComplete="given-name"
        required
        disabled={convertKit.state !== 'idle' || success}
      />

      <Field
        name="email"
        label="Email"
        autoComplete="email"
        error={convertKitData?.status === 'error' ? convertKitData.errors.email : null}
        disabled={convertKit.state !== 'idle' || success}
      />

      {success ? (
        <div className="flex">
          <CheckIcon />
          <p className="text-white">
            {userInfo?.convertKit
              ? `Genial, todo está configurado.`
              : `Genial, verifica tu correo para la confirmación.`}
          </p>
        </div>
      ) : (
        <div>
          <Button className="md:pt-10" type="submit" variant="primary">
            Suscribirme
          </Button>
        </div>
      )}
    </convertKit.Form>
  );
}

export { ConvertKitForm };
