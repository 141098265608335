import { ConvertKitForm } from '~/convertkit/form.tsx';
import { getImgProps, images } from '~/images.tsx';
import { type JasonifyUser } from '~/types.tsx';

const Footer = ({ user }: { user?: JasonifyUser | null | undefined }) => {
  return (
    <div className="w-full bg-red-300 dark:bg-gray-800">
      <div className="ml-auto mr-auto w-full max-w-7xl pb-0 pl-4 pr-4 pt-16 md:px-12 lg:px-2">
        <div className="row-gap-6 sm:grid-cols-2 mb-8 grid gap-10 lg:grid-cols-2 ">
          <div>
            <img
              {...getImgProps(images.ilagineWhite, {
                widths: [300, 600, 900, 1700, 2500],
                sizes: ['(max-width: 1023px) 80vw', '(min-width:1024px) and (max-width:1620px) 25vw', '410px']
              })}
            />
            {user?.convertKitId ? null : <ConvertKitForm formId="newsletter" convertKitFormId="4824908" />}
          </div>
          <div>
            <p className="text-center text-[24px] font-bold tracking-wide text-white md:text-left">
              APRENDE MÁS CON NUESTRAS CHARLAS EN VIVO
            </p>
            <p className="mt-4 text-center text-[20px]  font-medium leading-loose text-white md:text-left">
              Si estas interesada en aprender mucho más y te interesan este tipo de videos, transmitimos videos en vivo
              todos los martes a las 7 pm en nuestro canal de tiktok. Te esperamos.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between pb-10 pt-5 md:flex-row lg:flex-row ">
          <div className="sm:space-x-5 mb-3 ml-0 flex flex-col  flex-wrap items-center justify-center gap-5 space-y-0 md:flex-row md:gap-8">
            <a
              href="/politica-de-devoluciones-o-cancelaciones"
              className="pr-0 text-center text-lg text-white transition-colors duration-300 hover:text-cyan-400"
            >
              Política de Devoluciones o Cancelaciones
            </a>
            <a
              href="/politica-de-privacidad"
              className="pr-0 text-lg text-white transition-colors duration-300 hover:text-cyan-400"
            >
              Política de privacidad
            </a>
            <a
              href="/terminos-y-condiciones"
              className="pr-0 text-lg text-white transition-colors duration-300 hover:text-cyan-400"
            >
              Términos &amp; Condiciones
            </a>
            <p className="pt-8 text-lg text-white">© Copyright 2023 Ilagine SAC. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
