import { Menu, MenuButton, MenuItems, MenuLink, MenuPopover, useMenuButtonContext } from '@reach/menu-button';
import { Link, useFetcher, useLocation } from '@remix-run/react';
import { clsx } from 'clsx';
import { AnimatePresence, motion, useAnimation, useReducedMotion } from 'framer-motion';
import * as React from 'react';
import { useEffect } from 'react';
import { useElementState } from '~/components/hooks/use-element-state.tsx';
import { LaptopIcon } from '~/components/icons.tsx';
import { MoonIcon } from '~/components/icons/moon-icon.tsx';
import { PersonIcon } from '~/components/icons/person-icon.tsx';
import { SunIcon } from '~/components/icons/sun-icon.tsx';
import { H1 } from '~/components/typography.tsx';
import { type JasonifyUser } from '~/types.tsx';
import { useRequestInfo } from '~/utils/request-info.tsx';
import { THEME_FETCHER_KEY, useOptimisticThemeMode } from '~/utils/theme.tsx';
import { useRootData } from '~/utils/use-root-data.ts';
import { ButtonLink } from './button.tsx';

const LINKS = [
  // { name: 'Aprende con nuestros videos', to: '/aprende-con-nuestros-videos' },
  { name: 'Cita médica virtual', to: '/cita-medica-virtual' },
  { name: 'Blog', to: '/blog' },
  { name: 'Contáctanos', to: '/contacto' }
];

const MOBILE_LINKS = [...LINKS, { name: 'Entrar', to: '/entrar' }];

function NavLink({ to, ...rest }: Omit<Parameters<typeof Link>['0'], 'to'> & { to: string }) {
  const location = useLocation();
  const isSelected = to === location.pathname || location.pathname.startsWith(`${to}/`);

  return (
    <li className="px-5 py-2">
      <Link
        prefetch="intent"
        className={clsx(
          'underlined hover:text-primary focus:text-primary block whitespace-nowrap text-lg font-medium focus:outline-none',
          {
            active: isSelected,
            'text-secondary': !isSelected
          }
        )}
        to={to}
        {...rest}
      />
    </li>
  );
}

const iconTransformOrigin = { transformOrigin: '50% 100px' };
function DarkModeToggle({ variant = 'icon' }: { variant?: 'icon' | 'labelled' }) {
  const requestInfo = useRequestInfo();
  const fetcher = useFetcher({ key: THEME_FETCHER_KEY });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? requestInfo.userPrefs.theme ?? 'system';
  const nextMode = mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system';

  const iconSpanClassName = 'absolute inset-0 transform transition-transform duration-700 motion-reduce:duration-[0s]';
  return (
    <fetcher.Form method="POST" action="/action/set-theme">
      <input type="hidden" name="theme" value={nextMode} />

      <button
        type="submit"
        className={clsx(
          'text-primary dark:text-white border-secondar hover:border-primary focus:border-primary inline-flex h-14 items-center justify-center overflow-hidden rounded-full border-2 p-1 transition focus:outline-none',
          {
            'w-14': variant === 'icon',
            'px-8': variant === 'labelled'
          }
        )}
      >
        {/* note that the duration is longer then the one on body, controlling the bg-color */}
        <div className="relative h-8 w-8">
          <span
            className={clsx(iconSpanClassName, mode === 'dark' ? 'rotate-0' : 'rotate-90')}
            style={iconTransformOrigin}
          >
            <MoonIcon />
          </span>
          <span
            className={clsx(iconSpanClassName, mode === 'light' ? 'rotate-0' : '-rotate-90')}
            style={iconTransformOrigin}
          >
            <SunIcon />
          </span>

          <span
            className={clsx(iconSpanClassName, mode === 'system' ? 'translate-y-0' : 'translate-y-10')}
            style={iconTransformOrigin}
          >
            <LaptopIcon size={32} />
          </span>
        </div>
        <span className={clsx('ml-4', { 'sr-only': variant === 'icon' })}>
          {`Switch to ${nextMode === 'light' ? 'dark' : 'light'} mode`}
        </span>
      </button>
    </fetcher.Form>
  );
}

function MobileMenuList() {
  const { isExpanded } = useMenuButtonContext();
  const shouldReduceMotion = useReducedMotion();

  useEffect(() => {
    if (isExpanded) {
      // don't use overflow-hidden, as that toggles the scrollbar and causes layout shift
      document.body.classList.add('fixed');
      document.body.classList.add('w-full');
      document.body.classList.add('overflow-y-scroll');
      // alternatively, get bounding box of the menu, and set body height to that.
      document.body.style.height = '100vh';
    } else {
      document.body.classList.remove('fixed');
      document.body.classList.remove('w-full');
      document.body.classList.remove('overflow-y-scroll');
      document.body.style.removeProperty('height');
    }
  }, [isExpanded]);

  return (
    <AnimatePresence>
      {isExpanded ? (
        <MenuPopover
          position={r => ({
            top: `calc(${Number(r?.top) + Number(r?.height)}px + 1.57rem)`, // 2.25 rem = py-4 from navbar
            left: 0,
            bottom: 0,
            right: 0
          })}
          style={{ display: 'block' }}
          className="z-50"
        >
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -50, opacity: 0 }}
            transition={{
              duration: shouldReduceMotion ? 0 : 0.15,
              ease: 'linear'
            }}
            className="bg-primary flex h-full flex-col overflow-y-scroll border-t border-gray-200 pb-12 dark:border-gray-600"
          >
            <MenuItems className="border-none bg-transparent p-0">
              {MOBILE_LINKS.map(link => (
                <MenuLink
                  className="hover:bg-secondary focus:bg-secondary text-primary border-b border-gray-200 px-5vw py-9 hover:text-current dark:border-gray-600"
                  key={link.to}
                  as={Link}
                  to={link.to}
                >
                  {link.name}
                </MenuLink>
              ))}
            </MenuItems>
          </motion.div>
        </MenuPopover>
      ) : null}
    </AnimatePresence>
  );
}

const topVariants = {
  open: { rotate: 45, y: 7 },
  closed: { rotate: 0, y: 0 }
};

const centerVariants = {
  open: { opacity: 0 },
  closed: { opacity: 1 }
};

const bottomVariants = {
  open: { rotate: -45, y: -5 },
  closed: { rotate: 0, y: 0 }
};

function MobileMenu() {
  const shouldReduceMotion = useReducedMotion();
  const transition = shouldReduceMotion ? { duration: 0 } : {};
  return (
    <Menu>
      {({ isExpanded }) => {
        const state = isExpanded ? 'open' : 'closed';
        return (
          <>
            <MenuButton className="inline-flex h-10 w-14 items-center justify-center rounded-full border-0 p-1 text-red-400 transition focus:outline-none dark:text-white">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.rect
                  animate={state}
                  variants={topVariants}
                  transition={transition}
                  x="6"
                  y="9"
                  width="20"
                  height="2"
                  rx="1"
                  fill="currentColor"
                />
                <motion.rect
                  animate={state}
                  variants={centerVariants}
                  transition={transition}
                  x="6"
                  y="15"
                  width="20"
                  height="2"
                  rx="1"
                  fill="currentColor"
                />
                <motion.rect
                  animate={state}
                  variants={bottomVariants}
                  transition={transition}
                  x="6"
                  y="21"
                  width="20"
                  height="2"
                  rx="1"
                  fill="currentColor"
                />
              </svg>
            </MenuButton>

            <MobileMenuList />
          </>
        );
      }}
    </Menu>
  );
}

// Timing durations used to control the speed of the team ring in the profile button.
// Time is seconds per full rotation
const durations = {
  initial: 40,
  hover: 3,
  focus: 3,
  active: 0.25
};

function ProfileButton({
  imageUrl,
  imageAlt,
  user,
  magicLinkVerified
}: {
  imageUrl: string;
  imageAlt: string;
  user: JasonifyUser | null;
  magicLinkVerified: boolean | undefined;
}) {
  const controls = useAnimation();
  const [ref, state] = useElementState();
  const shouldReduceMotion = useReducedMotion();

  React.useEffect(() => {
    void controls.start((_, { rotate = 0 }) => {
      const target = typeof rotate === 'number' ? (state === 'initial' ? rotate - 360 : rotate + 360) : 360;

      return shouldReduceMotion
        ? {}
        : {
            rotate: [rotate, target],
            transition: {
              duration: durations[state],
              repeat: Infinity,
              ease: 'linear'
            }
          };
    });
  }, [state, controls, shouldReduceMotion]);

  if (!user && !magicLinkVerified) {
    return (
      <ButtonLink to="/entrar" variant="primary" prefetch="intent" size="medium" className="hidden lg:flex">
        ENTRAR
      </ButtonLink>
    );
  }

  return (
    <Link
      prefetch="intent"
      to={user ? '/app' : magicLinkVerified ? '/registrarse' : '/entrar'}
      aria-label={user ? 'Mi cuenta' : magicLinkVerified ? 'Terminar registro' : 'Entrar'}
      className={clsx('ml-4 inline-flex h-14 w-14 items-center justify-center rounded-full focus:outline-none')}
      ref={ref}
    >
      {imageUrl ? (
        <img className={clsx('inline w-10 select-none rounded-full')} src={imageUrl} alt={imageAlt} />
      ) : (
        <PersonIcon />
      )}
    </Link>
  );
}

export default function Navbar() {
  const { requestInfo, userInfo, user } = useRootData();
  const avatar = userInfo?.avatar ?? { src: '', alt: 'empty' };
  return (
    <div className="px-5vw py-4 lg:py-12">
      <nav className="mx-auto flex max-w-7xl items-center justify-between bg-cover bg-top">
        <Link
          prefetch="intent"
          to="/"
          className="flex flex-col whitespace-nowrap font-medium transition focus:outline-none"
        >
          <H1 className="text-red-400 dark:text-white">ILAGINE</H1>
          <span className="text-[6px] text-red-400 dark:text-white lg:text-[8.5px]">
            Instituto Latinoamericano de Ginecología
          </span>
        </Link>

        <ul className="hidden lg:flex">
          {LINKS.map(link => (
            <NavLink key={link.to} to={link.to}>
              {link.name}
            </NavLink>
          ))}
        </ul>

        <div className="flex items-center space-x-4">
          <div className="noscript-hidden lg:block">
            <DarkModeToggle />
          </div>
          <div className="block lg:hidden">
            <MobileMenu />
          </div>

          <ProfileButton
            magicLinkVerified={requestInfo.session.magicLinkVerified}
            imageUrl={avatar.src}
            imageAlt={avatar.alt}
            user={user}
          />
        </div>
      </nav>
    </div>
  );
}
