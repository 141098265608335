import { MetronomeLinks } from '@metronome-sh/react';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type DataFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
  type SerializeFrom } from
'@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteError } from
'@remix-run/react';
import { clsx } from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { MDXEmbedProvider } from 'mdx-embed';
import * as React from 'react';
import { useSpinDelay } from 'spin-delay';
import { ArrowLink } from '~/components/arrow-button.tsx';
import { ErrorPage } from '~/components/errors.tsx';
import Footer from '~/components/footer.tsx';
import Navbar from '~/components/navbar.tsx';
import { NotificationMessage } from '~/components/notification-message.tsx';
import { Spacer } from '~/components/spacer.tsx';
import { getGenericSocialImage } from '~/images.tsx';
import { type KCDHandle, type User } from '~/types.tsx';
import { getInstanceInfo } from '~/utils/cjs/litefs-js.server.js';
import { getClientSession } from '~/utils/client.server.ts';
import { getEnv } from '~/utils/env.server.ts';
import { getLoginInfoSession } from '~/utils/login.server.ts';
import { getDisplayUrl, getDomainUrl, getUrl } from '~/utils/misc.tsx';
import { getSession } from '~/utils/session.server.ts';
import { useTheme } from '~/utils/theme.tsx';
import { getServerTimeHeader } from '~/utils/timing.server.ts';
import { getUserInfo } from '~/utils/user-info.server.ts';
import { WhatsappIcon } from './components/icons/whatsapp.tsx';
import { Grimmacing, MissingSomething } from './components/kifs.tsx';
import appStyles from './styles/app.css';
import noScriptStyles from './styles/no-script.css';
import proseStyles from './styles/prose.css';
import tailwindStyles from './styles/tailwind.css';
import vendorStyles from './styles/vendor.css';
import { ClientHintCheck, getHints } from './utils/client-hints.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { getSocialMetas } from './utils/seo.ts';
import { getTheme } from './utils/theme.server.ts';

export const handle: KCDHandle & {id: string;} = {
  id: 'root'
};

export type LoaderData = SerializeFrom<typeof loader>;

export async function loader({ request }: DataFunctionArgs) {
  const timings = {};
  const session = await getSession(request);
  const user: User | null = await session.getUser({ timings });
  const clientSession = await getClientSession(request, user);
  const loginInfoSession = await getLoginInfoSession(request);
  const { primaryInstance } = await getInstanceInfo();

  const data = {
    user,
    userInfo: user ? await getUserInfo(user, { request, timings }) : null,
    ENV: getEnv(),
    requestInfo: {
      hints: getHints(request),
      origin: getDomainUrl(request),
      path: new URL(request.url).pathname,
      flyPrimaryInstance: primaryInstance,
      userPrefs: {
        theme: getTheme(request)
      },
      session: {
        email: loginInfoSession.getEmail(),
        magicLinkVerified: loginInfoSession.getMagicLinkVerified()
      }
    }
  };

  const headers: HeadersInit = new Headers();
  // this can lead to race conditions if a child route is also trying to commit
  // the cookie as well. This is a bug in remix that will hopefully be fixed.
  // we reduce the likelihood of a problem by only committing if the value is
  // different.
  await session.getHeaders(headers);
  await clientSession.getHeaders(headers);
  await loginInfoSession.getHeaders(headers);
  headers.append('Server-Timing', getServerTimeHeader(timings));

  return json(data, { headers });
}

export type RootLoaderType = typeof loader;

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? ''
  };
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const requestInfo = data?.requestInfo;

  return [
  { viewport: 'width=device-width,initial-scale=1,viewport-fit=cover' },
  { 'theme-color': requestInfo?.userPrefs.theme === 'dark' ? '#1F2028' : '#FFF' },
  ...getSocialMetas({
    keywords: 'infecciones, consejos, infecciones vaginales, infecciones urinarias, salud femenina, mujer salud',
    url: getUrl(requestInfo),
    image: getGenericSocialImage({
      url: getDisplayUrl(requestInfo),
      words: 'Conoce tu zona íntima con videos reales en nuestro contenido PREMIUM',
      featuredImage: 'ilagine.com/homepage_eqxaem.png'
    }),
    title: 'Ilagine',
    description: 'Conoce tu zona íntima con videos reales en nuestro contenido PREMIUM'
  })];

};

export const links: LinksFunction = () => [
{
  rel: 'apple-touch-icon',
  sizes: '180x180',
  href: '/favicons/apple-touch-icon.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '32x32',
  href: '/favicons/favicon-32x32.png'
},
{
  rel: 'icon',
  type: 'image/png',
  sizes: '16x16',
  href: '/favicons/favicon-16x16.png'
},
{ rel: 'manifest', href: '/site.webmanifest' },
{ rel: 'icon', href: '/favicon.ico' },
{ rel: 'stylesheet', href: vendorStyles },
{ rel: 'stylesheet', href: tailwindStyles },
{ rel: 'stylesheet', href: proseStyles },
{ rel: 'stylesheet', href: appStyles },
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


const LOADER_WORDS = [
'cargando',
'verificando cdn',
'verificando cache',
'consultando la bd',
'compilando mdx',
'actualizando cache',
'transfiriendo'];


const ACTION_WORDS = ['packaging', 'zapping', 'validating', 'processing', 'calculating', 'computing', 'computering'];

// we don't want to show the loading indicator on page load
let firstRender = true;

function PageLoadingMessage() {
  const navigation = useNavigation();
  const [words, setWords] = React.useState<Array<string>>([]);
  const [pendingPath, setPendingPath] = React.useState('');
  const showLoader = useSpinDelay(Boolean(navigation.state !== 'idle'), {
    delay: 400,
    minDuration: 1000
  });

  React.useEffect(() => {
    if (firstRender) return;
    if (navigation.state === 'idle') return;
    if (navigation.state === 'loading') setWords(LOADER_WORDS);
    if (navigation.state === 'submitting') setWords(ACTION_WORDS);

    const interval = setInterval(() => {
      setWords(([first, ...rest]) => ([...rest, first] as Array<string>));
    }, 2000);

    return () => clearInterval(interval);
  }, [pendingPath, navigation.state]);

  React.useEffect(() => {
    if (firstRender) return;
    if (navigation.state === 'idle') return;
    setPendingPath(navigation.location.pathname);
  }, [navigation]);

  React.useEffect(() => {
    firstRender = false;
  }, []);

  const action = words[0];
  return (
    <NotificationMessage position="bottom-right" visible={showLoader}>
      <div className="flex w-64 items-center">
        <div className="ml-4 inline-grid">
          <AnimatePresence>
            <div className="col-start-1 row-start-1 flex overflow-hidden">
              <motion.span
                key={action}
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -15, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex-none">

                {action}
              </motion.span>
            </div>
          </AnimatePresence>
          <span className="text-secondary truncate">path: {pendingPath}</span>
        </div>
      </div>
    </NotificationMessage>);

}

declare global {
  interface Window {
    fathom:
    {
      trackPageview(): void;
    } |
    undefined;
  }
}

export const App = (): React.ReactElement => {
  const data = useLoaderData<LoaderData>();
  const nonce = useNonce();
  const theme = useTheme();

  return (
    <html lang="es" className={clsx(theme)}>
      <head>
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1,viewport-fit=cover" />
        <Links />
        {ENV.NODE_ENV === 'production' ? <MetronomeLinks nonce={nonce} /> : null}
        <noscript>
          <link rel="stylesheet" href={noScriptStyles} />
        </noscript>
      </head>
      <body className="bg-white dark:bg-black">
        <PageLoadingMessage />
        <NotificationMessage queryStringKey="message" delay={0.3} />
        <Navbar />
        <Outlet />
        <Spacer size="base" />
        <Footer user={data.user} />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          nonce={nonce}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)};`
          }} />

        <a
          href="https://api.whatsapp.com/send?phone=51984401997&text=%C2%BF%20Quiero%20una%20cita%20%3F"
          className="float"
          target="_blank"
          rel="noreferrer">

          <WhatsappIcon className="my-float" />
        </a>
        {ENV.NODE_ENV === 'development' ?
        <>
            <LiveReload nonce={nonce} />
            <script nonce={nonce} suppressHydrationWarning dangerouslySetInnerHTML={{ __html: getWebsocketJS() }} />
          </> :
        null}
      </body>
    </html>);

};

export default function AppWithProviders(): React.ReactElement {
  return (
    <MDXEmbedProvider>
      <App />
    </MDXEmbedProvider>);

}

function ErrorDoc({ children }: {children: React.ReactNode;}) {
  const nonce = useNonce();
  return (
    <html lang="en" className="dark">
      <head>
        <title>Oh no...</title>
        <Links />
      </head>
      <body className="bg-white transition duration-500 dark:bg-gray-900">
        {children}
        <Scripts nonce={nonce} />
      </body>
    </html>);

}

// best effort, last ditch error boundary. This should only catch root errors
// all other errors should be caught by the index route which will include
// the footer and stuff, which is much better.
export function ErrorBoundary() {
  const error = useRouteError();
  const location = useLocation();

  if (isRouteErrorResponse(error)) {
    console.error('CatchBoundary', error);
    if (error.status === 404) {
      return (
        <ErrorDoc>
          <ErrorPage
            heroProps={{
              title: '404 - Oh no, encontraste una página que le está faltando datos.',
              subtitle: `"${location.pathname}" No es una página en ilagine.com. Lo sentimos.`,
              image: <MissingSomething className="rounded-lg" aspectRatio="3:4" />,
              action: <ArrowLink href="/">Al Inicio</ArrowLink>
            }} />

        </ErrorDoc>);

    }
    if (error.status === 409) {
      return (
        <ErrorDoc>
          <ErrorPage
            heroProps={{
              title: '409 - Oh no, Nunca ha debído ver este mensaje.',
              subtitle: `"${location.pathname}" intenta hacer de nuevo tu solicitud.`,
              image: <Grimmacing className="rounded-lg" aspectRatio="3:4" />,
              action: <ArrowLink href="/">Al Inicio</ArrowLink>
            }} />

        </ErrorDoc>);

    }
    if (error.status !== 500) {
      return (
        <ErrorDoc>
          <ErrorPage
            heroProps={{
              title: `${error.status} - Oh no, algo salió mal.`,
              subtitle: `"${location.pathname}" actualmente no está funcionando. Lo sentimos.`,
              image: <Grimmacing className="rounded-lg" aspectRatio="3:4" />,
              action: <ArrowLink href="/">Al Inicio</ArrowLink>
            }} />

        </ErrorDoc>);

    }
    throw new Error(`Unhandled error: ${error.status}`);
  }

  console.error(error);
  return (
    <ErrorDoc>
      <ErrorPage
        heroProps={{
          title: '500 - Oh no, algo salió mal.',
          subtitle: `"${location.pathname}" actualmente no está funcionando. Lo sentimos.`,
          image: <Grimmacing className="rounded-lg" aspectRatio="3:4" />,
          action: <ArrowLink href="/">Al Inicio</ArrowLink>
        }} />

    </ErrorDoc>);

}

function ilagineLiveReloadConnect(config?: {onOpen: () => void;}) {
  const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
  const host = location.hostname;
  const port = location.port;
  const socketPath = `${protocol}//${host}:${port}/__ws`;
  const ws = new WebSocket(socketPath);
  ws.onmessage = (message) => {
    const event = JSON.parse(message.data);
    if (event.type === 'ilagine.com:file-change' && event.data.relativePath === location.pathname) {
      window.location.reload();
    }
  };
  ws.onopen = () => {
    if (config && typeof config.onOpen === 'function') {
      config.onOpen();
    }
  };
  ws.onclose = (event) => {
    if (event.code === 1006) {
      console.log('ilagine.com dev server web socket closed. Reconnecting...');
      setTimeout(
        () =>
        ilagineLiveReloadConnect({
          onOpen: () => window.location.reload()
        }),
        1000
      );
    }
  };
  ws.onerror = (error) => {
    console.log('ilagine.com dev server web socket error:');
    console.error(error);
  };
}

function getWebsocketJS() {
  const js = /* javascript */`
  ${ilagineLiveReloadConnect.toString()}
  ilagineLiveReloadConnect();
  `;
  return js;
}